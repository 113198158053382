// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/bright_cs_bg.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.app-background {
  position: relative; /* Required for positioning the pseudo-element */
  min-height: 100vh; /* Ensures the background covers at least the viewport height */
  overflow: hidden; /* Ensures no overflow if the image is larger */
}

.app-background::before {
  content: ''; /* Necessary to create the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Replace with your actual image file name */
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repetition */
  filter: blur(1px); /* Adjust the blur intensity (increase or decrease as needed) */
  z-index: -1; /* Ensures the image is behind all other content */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,kBAAkB,EAAE,gDAAgD;EACpE,iBAAiB,EAAE,+DAA+D;EAClF,gBAAgB,EAAE,+CAA+C;AACnE;;AAEA;EACE,WAAW,EAAE,2CAA2C;EACxD,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,yDAAkD,EAAE,6CAA6C;EACjG,sBAAsB,EAAE,mDAAmD;EAC3E,2BAA2B,EAAE,sBAAsB;EACnD,4BAA4B,EAAE,wBAAwB;EACtD,iBAAiB,EAAE,+DAA+D;EAClF,WAAW,EAAE,kDAAkD;AACjE","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: 'PT Sans', sans-serif;\n}\n\n.app-background {\n  position: relative; /* Required for positioning the pseudo-element */\n  min-height: 100vh; /* Ensures the background covers at least the viewport height */\n  overflow: hidden; /* Ensures no overflow if the image is larger */\n}\n\n.app-background::before {\n  content: ''; /* Necessary to create the pseudo-element */\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-image: url(\"/public/bright_cs_bg.webp\"); /* Replace with your actual image file name */\n  background-size: cover; /* Ensures the image covers the entire background */\n  background-position: center; /* Centers the image */\n  background-repeat: no-repeat; /* Prevents repetition */\n  filter: blur(1px); /* Adjust the blur intensity (increase or decrease as needed) */\n  z-index: -1; /* Ensures the image is behind all other content */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
