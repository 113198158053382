import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'; 
import AppRouter from './router';
import Navbar from './common/components/Navbar';
import Contact from './pages/Contact';
import { useRef } from 'react';
import ScrollToTop from './common/components/ScrollToTop';

function App() {

  const contactRef = useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Contact component is not available for scrolling.');
    }
  };

  return (
    <div className='app-background'>
      <Router>
        <ScrollToTop />
        <Navbar scrollToContact={scrollToContact}/>
        <AppRouter scrollToContact={scrollToContact}/>
        <div>
          <Contact ref={contactRef}/>
        </div>
      </Router>
    </div>
  );
}

export default App;
