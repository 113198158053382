import React, { forwardRef } from "react";
import "./contact.css";
import { Link } from "react-router-dom";
import {
  // FaFacebook,
  // FaInstagram,
  // FaYoutube,
  // FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const Contact = forwardRef((props, ref) => {
  return (
    <div ref={ref} id="contact" className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Get in Touch with CloudShastra Infotech Solutions
        </p>
        <p className='footer-subscription-text'>
        At CloudShastra Infotech Solutions, we are here to help you harness the full potential of the cloud. 
        Whether you need expert advice on cloud strategy, migration, DevOps services, or ongoing 
        infrastructure management, our team of certified cloud professionals is ready to assist you.
        </p>
        {/* <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div> */}
      </section>
      <div className="footer-link-wrapper">
        <div className="footer-link-items">
          <h2>About Us</h2>
          <Link to="/about">Who we are ?</Link>
          {/* <Link to='/'>Testimonials</Link>
          <Link to='/'>Careers</Link>
          <Link to='/'>Investors</Link>
          <Link to='/'>Terms of Service</Link> */}
        </div>
        <div className="footer-link-items">
          <h2>Address</h2>
          <Link>S. No. 10266, Miraj</Link>
          <Link>Sangli</Link>
          <Link>Maharashtra</Link>
          <Link>416410</Link>
        </div>
        <div className="footer-link-items">
          <h2>Contact Us</h2>
          <Link>+91 9766007557</Link>
          <Link>contact@cloudshastrainfotect.com</Link>
          <Link>Monday - Friday: 10:00 AM - 7:00 PM (IST)</Link>
          <Link>Saturday - Sunday: Closed</Link>
        </div>
        <div className="footer-link-items">
          <h2>Social Media</h2>
          <Link
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/cloudshastra-infotech-solutions/",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            LinkedIn
          </Link>
          {/* <Link to='/'>Instagram</Link>
          <Link to='/'>Facebook</Link>
          <Link to='/'>Youtube</Link>
          <Link to='/'>Twitter</Link> */}
        </div>
      </div>
      {/* <div className="footer-link-wrapper">
        <div className='footer-link-items'>
          <h2>Videos</h2>
          <Link to='/'>Submit Video</Link>
          <Link to='/'>Ambassadors</Link>
          <Link to='/'>Agency</Link>
          <Link to='/'>Influencer</Link>
        </div>
      </div> */}
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img
                src="/Cloud Shastra Logo White.png"
                alt="Cloud Shastra Logo"
                className="footer-logo-img"
              />
              CLOUD SHASTRA
            </Link>
          </div>
          <small className="website-rights">CLOUD SHASTRA © 2024</small>
          <div className="social-icons">
            {/* <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <FaTwitter />
            </Link> */}
            <Link
              className="social-icon-link"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/cloudshastra-infotech-solutions/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Contact;
