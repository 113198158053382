import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory from react-router-dom
import './button.css';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large', 'btn--mobile', 'btn--wide'];
const COLOR = ['primary', 'blue', 'red', 'green'];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor,
  to // Add to prop for routing
}) => {
  const navigate = useNavigate(); // Get the navigate object

  const handleClick = () => {
    if (to) {
      navigate(to); // Navigate to the provided path
    }
    if (onClick) {
      onClick(); // Call any additional onClick handler if provided
    }
  };

  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`}
      onClick={handleClick}
      type={type}
    >
      {children}
    </button>
  );
};
